import { LogsInitConfiguration } from '@datadog/browser-logs'
import { RumInitConfiguration } from '@datadog/browser-rum'

export const APP_NAME = 'abstrid'
export const CDN_URL = 'https://abstrid.mmfg.it/test'

export const ABSTRID_EA = 'abstrid-ea'
export const images = {
  baseUrl: 'https://dx0woejilafh2.cloudfront.net/sys-master/m0',
}

export const menu = {
  topLevelOrder: [
    'POS:::dashbord_stock',
    'POS:::dashboard_backoffice',
    'POS:::dashboard_frontoffice',
  ],
  shortcutIds: ['POS:::vendita', 'POS:::resi'],
}

/**
 * A list of pages that are actually just links to pages on BMS.
 */
export const ADDITIONAL_BMS_PAGES = [
  'POS:::KPIB2CRiepilogo',
  'POS:::KPIB2CDettaglio',
  'POS:::KPIGiornalieri',
  'POS:::B2EAvanzamentoOrdini',
  'POS:::resi_b2c_list',
]

export const datadogConfigs: RumInitConfiguration & LogsInitConfiguration = {
  applicationId: '166d7e05-1c89-484c-99b1-3225ca90af31',
  clientToken: 'pube893982f1666cdce9e62cc3dbbee67e5',
  site: 'datadoghq.eu',
  service: 'posweb-frontend',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
  forwardConsoleLogs: ['error'],
  forwardReports: 'all',
  trackFrustrations: true,
  trackLongTasks: true,
  trackUserInteractions: true,
}

export const OM_BASE_PATH_PROD = 'https://om.mmfg.it'
export const OM_BASE_PATH_TEST = 'https://test.om.mmfg.it'

export const SID_DURATION = 1000 * 60 * 60

export const SUPER_USER_ROLE = 'POS:::Super'

/**
 * A list of modules and their corresponding configuration parameters that can be enabled or disabled in the application.
 * Each module is represented as an object with two properties:
 * - `param`: The name of the configuration parameter (in configs Store) that controls whether the module is enabled or disabled.
 * - `module`: The name of the module (folder name).
 */
export const MODULES = [
  {
    param: '',
    module: 'crm-eventi',
  },
  {
    param: 'ENDLESSAISLE_ENABLED',
    module: 'endless-aisle',
  },
  {
    param: 'PROMO_ENGINE_ENABLED',
    module: 'promo-engine',
  },
  {
    param: 'PETTY_CASH_MODE',
    module: 'petty-cash-v2',
  },
  {
    param: 'PICKING_ENABLED',
    module: 'picking',
  },
  {
    param: 'ADDRESS_VALIDATION',
    module: 'address-validation',
  },
]

/**
 * Secret token to access the hidden Live Update UI.
 */
export const LIVE_UPDATE_UI_TOKEN = 'FgAbs!'

/**
 * Amount of milliseconds to wait before giving up with Live Updates.
 * This is the total time between user opening the application and the first Live Update chunk being downloaded.
 */
export const LIVE_UPDATE_INITIAL_TIMEOUT_MS = 3_000

/**
 * Amount of milliseconds to wait for each chunk to download before giving up with Live Updates.
 */
export const LIVE_UPDATE_CHUNK_TIMEOUT_MS = 3_000

/**
 * Amount of milliseconds to wait before giving up with Live Updates.
 * This is the total time from the application being opened to the last chunk of a Live Update being downloaded and
 * installed. Hence, this is the maximum time we wait (start to finish) before preceding without live updates.
 */
export const LIVE_UPDATE_MAXIMUM_TIMEOUT_MS = 10_000

/**
 * Name of the Live Update channel that will be used as a fallback in case all other channels cannot be used.
 */
export const LIVE_UPDATE_FALLBACK_CHANNEL = 'Production'

/**
 * A key-value object where the key is a deploy_env from the "global_config" Posweb API.
 * Corresponding values are an array of strings, representing names of Appflow Live Update Channel(s) used with the
 * associated deploy_env. Doing so, we can have Live Update Channels that are visibile only by certain deploy envs.
 * The first item of each list is the Live Channel used by default by the application in that landscape.
 */
export const LIVE_UPDATE_CHANNELS = {
  live: ['Production'],
  demo: ['Production', 'UAT'],
  development: ['Testing', 'UAT', 'Production'],
}
